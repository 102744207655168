import * as React from "react";

import Topic from "../components/topic";

// markup
const ServicePage = () => {
  const post = {
    title: 'Hire Dedicated MVP Developers | Offshore MVP Development Company in India',
    metaDescription: '',
    h1: 'Hire Dedicated MVP Developers',
    h1Subtext1: 'Quickly build minimum viable product (MVP) and validate you idea with MatteCurve team.',
    h1Subtext2: 'MatteCurve is the best MVP development company in India. We are a JavaScript company and have dedicated Product developers working remotely. We work with fortune 500 companies and startups to build end-to-end solutions based on the JavaScript ecosystem. Share your requirements and hire Product developers within 7 days.',
    aboutSection: {
        title: 'About MVP',
        subTitle: 'Building a software version with core features and minimalist design, using basic infrastructure.',
        paragraph1: "In minimum viable product (MVP) development, only core features are developed and release to limited users to validate the idea. Features and user interface should not be perfect in mvp.",
        paragraph2: "In mvp development, only those features should be included which can help to validate your idea. User interface should not be much important but it should explain the feature clearly. In mvp we don't use large infrastructure as we want only limited users to use the mvp and give use feedback quickly. MVP should be developed quickly as there is no standard timeline but it should be quick.",
        paragraph3: "MVP developers are not different than product / software developers but they should be well expert in quickly building and releasing the product.",
        keyPoints: [
            "MVP is Minimum Viable Product.",
            "A MVP should have must-have features which defines the value.",
            "The purpose to build an MVP is to launch a product quickly, based on your idea, with a small budget.",
            "MVP gives the opportunity to test the product early.",
            "MVP gives the opportunity to validate the market.",
            "MVP should be developed in limited time and limited budget.",
            "MVP should be launched as soon as it is built.",
            "Developing MVP and validating your idea reduces high risk of developing full product.",
            "Release improvements to the product quickly and inexpensively as you learn about your market and your solution."
        ],
    },
    statsSection: {
        paragraph: "MVP development is an essential step to reduce higher risk. Hire dedicated team in India from MatteCurve to build your MVP.",
    },
    benefitsSection: {
        title: "Benefits of Hiring Dedicated MVP App Developers",
        keyPoints: [
            "Improve Project Outcomes",
            "Cost-effective",
            "No investment On Hardware/Software Requirement",
            "Get Access to Skilled Developers",
            "Agile Approach",
            "Collaborative Engagement",
            "Painless Project Management",
            "Greater focus on business"
        ],
        image: "https://images.unsplash.com/photo-1603404235009-71c1f80e31fc?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1000&q=80",
    },
    ctaTexts: [
        "Hire dedicated MVP Developers?",
        "Hire dedicated MVP Developers from MatteCurve?",
    ],
    why: {
        title: "Why Choose MatteCurve for MVP Development?",
        paragraph: "MatteCurve has been recognized as one of the leading web development companies in building high-performance MVP apps from India. MatteCurve offers you deep expertise in MVP web development and MVP app development, delivered by a team of highly-skilled MVP Developers with unmatched expertise in building progressive and dynamic web apps using MVP.",
    }
  };


  return (
    <Topic post={post}></Topic>
  )
}

export default ServicePage;
